/* src/assets/styles/global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
  font-family: Arial, sans-serif;
}

.size-20 {
  width: 80px;
  height: 80px;
}

.size-8 {
  width: 32px;
  height: 32px;
}

.size-3.5 {
  width: 14px;
  height: 14px;
}
